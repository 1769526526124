import React from 'react';
import Flex, { FlexItem } from 'styled-flex-component';

import SubLayout from '../components/SubLayout';
import Form from '../components/AntiBodyCampaign';

const Contact = () => (
  <SubLayout topTitle="抗体検査割引キャンペーン">
    <Flex center>
      <FlexItem
        css={`
          margin-top: 80px;
          margin-bottom: 80px;
          width: 96%;
        `}
      >
        <Form />
      </FlexItem>
    </Flex>
  </SubLayout>
);

export default Contact;
