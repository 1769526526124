import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import AWS from 'aws-sdk';

const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.14px;
  color: #6f6f6f;
  margin-bottom: 10px;
`;

const InputCommonStyle = `
  height: 40px;
  width: 100%;
  line-height: 30px;
  font-size: 18px;
  color: #000000;
  border-width: 0;
  &:focus {
    outline: inherit;
  }
`;

const Form = ({ lang = 'ja' }) => {
    return (
        <Flex >
            <p>
                〈〈 新型コロナウイルスの抗体検査  〉〉<br/>
                当院の渡航PCR検査をご希望される方の抗体検査費は半額キャンペーン中！<br/>
                <br/>
                ◆概要<br/>
                当院では、新型コロナウイルス過去の感染の有無や、ワクチン接種によって得られた抗体の量を調べる抗体検査（血液検査）を行っております。精度と信頼性に定評があるアボット社のARCHITECT SARS-CoV-2 IgG II Quantを採用しております。<br/>
                <br/>
                ◆検査方法<br/>
                血液検査：検査結果判明まで3日ほどかかります（休日除く）<br/>
                ※検査結果のPDF版報告書をメール送付サービス（キャンペーン：通常500円が無料に）<br/>
                <br/>
                ◆検査詳細<br/>
                A）新型コロナウイルスIgG定量（S蛋白）検査<br/>
                新型コロナワクチンを接種すると、スパイクタンパク質（S）に対する抗体がつくられます。抗体検査（S）を受けることで、からだの中に抗体がどれくらいあるかを調べることができます。ワクチンを接種してから抗体ができるまで約1～2週間程度かかるといわれています。また、過去の感染によってもスパイクタンパク室に対する抗体は作られます。<br/>
                <br/>
                B）新型コロナウイルスIgG定量（N蛋白）検査<br/>
                ワクチンの接種の有無に影響されることはなく、過去に新型コロナウイルスに感染したかを調べることができます。<br/>
                <br/>
                ◆検査費用<br/>
                ◇IgG定量（S）蛋白検査<br/>
                3,000円（税込・当院渡航PCRご希望方のみの特別価格 ※通常6,000円）<br/>
                <br/>
                ◇IgG定量（N）蛋白検査<br/>
                3,000円（税込・当院渡航PCRご希望方のみの特別価格 ※通常4,000円）<br/>
                <br/>
                ※健康保険適応外です。<br/>
                ※こちらのキャンペーンは渡航用PCR検査を受診された方のみに適用されます。抗体検査単独ではキャンペーンは適用されませんので、ご了承ください。<br/>
            </p>
        </Flex>
    );
};

export default Form;